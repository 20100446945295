<template>
  <div
    v-if="model.ingredientsTitle"
    class="c-product-ingredients"
  >
    <strong class="c-product-nutrition-title e-label-heading u-font-size-xlarge u-flex u-width-max">{{ model.ingredientsTitle }}</strong>
    <div
      class="u-font-size-medium u-mt--xs"
      v-html="model.ingredientsText"
    />
  </div>
</template>

<script>
export default {
  props: {
    model: {
      type: Object,
      required: true
    }
  }
}
</script>
